<template>
	<div class="main">
		<div class="perf-info-box">
			<div class="table-box">
				<div class="table-title-line">
					<div class="table-cell" style="width: 30vw">年月</div>
					<div class="table-line-icon"></div>
					<div class="table-cell" style="width: 35vw">被考核人</div>
					<div class="table-line-icon"></div>
					<div class="table-cell" style="width: 35vw">审批状态</div>
				</div>
				<template  v-if="list">
					<div @click="toAdd(item)" v-for="(item, index) in list" :key="index" class="table-line">
						<div class="table-cell" style="width: 30vw">{{item.years}}</div>
						<div class="table-cell" style="width: 35vw">{{item.userName}}</div>
						<div class="table-cell" style="width: 35vw">
							<span v-if="item.status === 1">待审核</span>
							<span v-if="item.status === 2">审核通过</span>
							<span v-if="item.status === 3">驳回</span>
							<span v-if="item.status === 4">完成</span>
						</div>
					</div>
				</template>
				<template v-else>
					<empty description="暂无数据" />
				</template>
			</div>
		</div>
		<div class="page-box">
			<van-pagination @change="changePage()" v-model="postData.page" :total-items="total" :items-per-page="20" />
		</div>
	</div>
</template>

<script>
	import { getPerfList, hrGetList} from '@/api/performance'
	import {Dialog, Empty} from 'vant';
	// import Cookies from "js-cookie";
	export default {
		name: 'DemandList',
		components: {
			Empty
		},
		data() {
			return {
				list: '',
				postData: {
					type: '',
					page: 1,
					pageSize: 20
				},
				level: '', // 1 领导审批，2 人事审批
				total: '',
				type: ''// 类型 1：任务审核列表，2 评分列表
			}
		},
		created() {
			const query = this.$route.query
			if (!query.type || !query.level) {
				Dialog.alert({title: '提示', message: '参数错误',})
				return
			}
			// 判断是否登录
			if(!sessionStorage.getItem("token")){
				//存储路由
				sessionStorage.setItem("perfPath", this.$route.path)
				sessionStorage.setItem("perfQuery", JSON.stringify(query))
				const postData = {}
				this.$router.push({ path: '/perfLogin', query: postData})
				return
			}
			this.type = query.type
			this.level = query.level
			this.postData.type = query.type
			this.getInfo()
		},
		mounted:function(){
			// 清除之前的缓存
			sessionStorage.removeItem('approvalData')
			sessionStorage.removeItem('prefData')
		},
		methods: {
			// 分页切换
			changePage(){
				this.getInfo()
			},
			// 获取信息
			getInfo(){
				this.postData.type = parseInt(this.postData.type)
				// 判断是领导 还是HR
				if(parseInt(this.level) === 1){
					getPerfList(this.postData).then((res) => {
						this.list = res.data.records;
						this.total = res.data.total;
					})
				}else{
					hrGetList(this.postData).then((res) => {
						this.list = res.data.records;
						this.total = res.data.total;
					})
				}
			},
			// 调到新增页面
			toAdd(row){
				console.log(this.type)
				if(parseInt(this.type) === 1){
					const postData = {}
					postData.id = row.id
					postData.level = this.level
					this.$router.push({ path: '/leaderIndex', query: postData})
				}else{
					const postData = {}
					postData.id = row.id
					this.$router.push({ path: '/appIndex', query: postData})
				}
			},
		}
	}
</script>

<style scoped>
	.main{
		color:#333333;
		font-size: 15px;
	}
	.perf-info-box{
		background: #FFFFFF;
		border-radius: 8px;
	}
	/*表格开始*/
	.table-box{
		width: calc(100vw - 2px);
		border-radius: 1px;
	}
	.table-line-icon{
		width: 1px;
		height: 100%;
		background: linear-gradient(180deg, rgba(255,255,255,0) 0%, #DDDDDD 53%, rgba(255,255,255,0) 100%);
		border-radius: 2px;
	}
	.table-title-line{
		width: 100%;
		display: flex;
		justify-content: space-between;
		background-color: #ffffff;
		color: #666666;
		height: 42px;
		line-height: 42px;
		font-weight: bold;
		text-align: center;
	}
	.table-line{
		width: 100%;
		display: flex;
		justify-content: space-between;
		color: #000000;
		height: 42px;
		line-height: 42px;
		text-align: center;
	}
	.table-cell{
		border-bottom: 1px solid #F6F6F6;
		overflow:hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		-o-text-overflow:ellipsis;
		padding: 0 5px;
	}
	/*表格结束*/
	.page-box{
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100vw;
	}
</style>
